// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  PROTOCOL: 'https',

  // FOR IAMGE PATH
  // IAMGE_PATH: 'https://mynotes-be.azurewebsites.net', 
  // API IMGAGE AZURE
  // IAMGE_PATH: 'https://sfbcapi.thequantumz.com', // API URL PRODUCTION
  // IAMGE_PATH: 'https://sfbcstgapi.thequantumz.com', //API URL STAGING

  // FOR API's
  baseURL: 'oncopapapi.thequantumz.com/api', 
  // Azure API URL
  // baseURL: 'sfbcapi.thequantumz.com/api', // API URL PRODUCTION
  // baseURL: 'sfbcstgapi.thequantumz.com/api', // API URL STAGING


  ROOT_URL: 'localhost', // LOCAL
  // ROOT_URL: 'tcapi.thequantumz.com',
   // AZURE
  // ROOT_URL: 'sfbc.thequantumz.com', // PROD
  // ROOT_URL: 'sfbcstg.thequantumz.com', // STAGING
};